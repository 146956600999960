* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-bg: #f8fdfc;
  --primary-text: #707070;
  --secondary-text: #1b2647;
}

