#importance {
  margin: 14rem 0 0 0;
  /* height: 230vh; */
}
#importance .container {
  position: relative;
  z-index: 5;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 25rem;
  gap: 2rem;
  grid-template-areas:
    ". a"
    "b ."
    ". c"
    "d .";
}

#importance img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

#importance .title {
  font-weight: bold;
  font-size: clamp(3.5rem, 5vw, 5rem);
  grid-area: a;
  margin-left: 10rem;
  font-family: Helvetica, sans-serif;
}

#importance .first-left {
  grid-area: b;
  margin-bottom: 50%;
}

#importance .first-right {
  grid-area: c;
  margin-bottom: 60%;
}
#importance .second-left {
  margin-bottom: 30%;
  grid-area: d;
}

#importance .first-left .first-left-title,
.first-right-title,
.second-left-title {
  margin-bottom: 3rem;
  font-size: clamp(2rem, 5vw, 3rem);
  color: #ED084D;
}
#importance .first-left .first-left-info,
.first-right-info,
.second-left-info {
  font-size: clamp(1rem, 5vw, 1.5rem);
  width: 90%;
}

@media only screen and (max-width: 900px) {
  #importance .first-left .first-left-info,
  .first-right-info,
  .second-left-info {
    font-size: clamp(1rem, 5vw, 1.5rem);
    width: 100%;
  }

}

@media only screen and (max-width: 768px) {
  #importance {
    margin: 5rem 0 0 0;
    height: 130vh;
  }
  #importance img {
    width: auto;
    opacity: 0.3;
    top: -10rem;
  }
  #importance .container {
    grid-template-columns: 1fr;
    grid-template-rows: 15rem 10rem;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d";
  }
  #importance .title {
    margin-left: 0rem;
  }
  #importance .first-right {
    margin-top: 40%;
    margin-bottom: 20%;
  }
  #importance .second-left {
    margin-bottom: 0%;
  }
  /* #importance.importance-section {
    margin-bottom: 500px;
  } */
}
