footer {
  background: linear-gradient(rgba(0, 0, 0, 0.582), rgba(0, 0, 0, 0.582)),
    url("../../assets/cutepuppy.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 80vh;
  color: white;
  position: relative;
}

footer .container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

footer .footerTitle {
  font-size: clamp(4.7rem, 10vw, 9.5rem);
  text-transform: capitalize;
  text-align: center;
  font-weight: bold;
  padding: 3rem 0;
}

footer .sendEmailBtn {
  padding: 1.2rem 6rem;
  background: #ED084D;
  border: 2px solid pink;
  color: white;
  font-size: clamp(1.3rem, 5vw, 1.7rem);
  letter-spacing: 0.1rem;
  transition: background 0.3s ease;
}

footer .sendEmailBtn:hover {
  background: #d10744;
}

@media only screen and (max-width: 600px) {
  footer .sendEmailBtn {
    padding: 1.2rem 3rem;
  }
}
